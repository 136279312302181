<template>
  <main class="flex-horizontal" style="color: green" :class="{ hidden: !show }">
    <section class="left-section">
      <DataList />
    </section>

    <section class="right-section">
      <DataView
        v-for="dataItem in selectedData.reverse()"
        :data-item="dataItem"
        :key="dataItem.id + '-dataview'"
      />
    </section>

    <!-- Show message when files get uploaded succesfully! -->
    <v-snackbar v-model="alert" :multi-line="true">
      <span v-html="message"></span>

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="alert = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </main>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import DataList from "../components/DataList.vue";
import DataView from "../components/DataView.vue";

export default {
  name: "Main",
  components: { DataList, DataView },
  data() {
    return {
      alert: false,
    };
  },

  methods: {
    parseLeadsInPath(idString = "") {
      // const idString = next.params.ids;
      let ids = idString.split("+") || [];

      // let ids = idString.split("+");
      ids = ids.map((id) => parseInt(id)).filter((id) => !isNaN(id));
      this.$store.commit("setSelectedDataIDs", { selectedDataIDs: ids });
    },
  },

  computed: {
    ...mapState(["showMessage", "message", "passphrase", "show"]),
    ...mapGetters(["selectedData"]),
  },

  mounted() {
    // this.$store.dispatch("askPassphrase");

    // Parse the leads
    if (this.$route.params?.ids) {
      this.parseLeadsInPath(this.$route.params.ids);
    }
  },

  watch: {
    showMessage(next) {
      this.alert = next;
    },

    alert(next) {
      if (next == false) this.$store.commit("clearMessage");
    },

    // Parse the selectedIDs in the route so it can be set to the global store
    // which then in turn updates the ui.
    $route(next) {
      if (next.path.includes("leads/")) {
        this.parseLeadsInPath(next.params.ids);
      }
    },
  },
};
</script>

<style>
.hidden {
  visibility: hidden;
  /* display: none; */
  /* background: 0; */
}

section {
  padding: 20px;
}

main {
  width: 100%;
  /* background: green; */
  height: 100%;
}

.left-section {
  width: 50%;
  flex: min(50%, 775px) 0 0;
  padding-right: 10px;
}

.right-section {
  flex: 1 1 auto;
  padding-left: 10px;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-horizontal {
  display: flex;
  flex-direction: row;
}

/* Portrait */
@media (max-width: 1024px) {
  .flex-horizontal {
    flex-direction: column;
  }

  .left-section {
    flex: 1;
    width: calc(100% - 10px);
  }
}
</style>
