let API_URL = process.env["NODE_ENV"] == "development" ? "http://localhost:3000/" : "https://carbon.pixelhobby.com/"
// let API_URL =  "https://carbon.pixelhobby.com/"

import store from '../store'

export default {

    // Automatically returns a promise since the requestData returns the promise.
    fetchSchema(resource){
        return requestData(API_URL + resource + "/schema.json");
    },

    fetchData(resource) {  
        return requestData(API_URL + resource + ".json");
    },

    async updateData(resource, id, data) {

        const url = API_URL + resource + "/" + id
            // TODO: Post data to server and notify if something went wrong or not.
        const headers = new Headers();
        headers.append('Authorization', 'Basic ' + btoa("pixelhobby" + ":" + store.state.passphrase));
        headers.append("Content-Type", "application/json")
        const request = await fetch(url, { headers, method: "PATCH", body: JSON.stringify(data) } )
        return await request.json()
    },

    async deleteID(resource, id){
        const url = API_URL + resource + "/" + id
        
        const headers = new Headers();
        headers.append('Authorization', 'Basic ' + btoa("pixelhobby" + ":" + store.state.passphrase));
        const request = await fetch(url, { headers, method: "DELETE" } )
        return await request.json()
    }

}

// Usefull function to get the request data.
async function requestData(url) {
    try {
        const headers = new Headers();
        headers.append('Authorization', 'Basic ' + btoa("pixelhobby" + ":" + store.state.passphrase));
        const request = await fetch(url, { headers })
        return await request.json()

    } catch (error) {
        return { errors: ["Could not communicate with the server."] }
    }
}
