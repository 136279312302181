import Vue from 'vue'
import Vuex from 'vuex'

import apiService from '@/services/api.js'
/* eslint-disable */ 

// TODO: add paginization - should also be added to the server (carbon - crud controller?)
// Beter yet - since we want to know all the ids beforehand
// get a list of all the ids and the column displayed in the list
// get the rest of the data when it is actually selected.

// Stored passphrase
let storedPass = localStorage.getItem("passphrase");
if (!storedPass || storedPass == 'null' || storedPass == 'undefined') storedPass = "";

Vue.use(Vuex)

const store = new Vuex.Store({
    state: () => ({

        resource: "leads",
        show: false, // toggle to hide our UI. (not signed in yet)
        
        // See this as our cache.
        data: [],
        schema: {}, // Gets all the properties and types of the data.
        selectedDataIDs: [],
        filter: {}, // Contains the current constraints to filter our data on.
        orFilter: true, // Whether the filter should be orred together.

        passphrase: storedPass,

        showMessage: false,
        message: "This is a test message.",
      }),

      getters: {

        // Return the ID found in the leads array.
        getDataById(state) {
          return id => state.data.find( d => d.id === id);
        },

        // Just another name to get the data.
        leads(state){
          return state.data;
        },

        // Return the selected data IDs.
        selectedData(state, getters) {
            var selectedData = []

            for (let i = 0; i < state.selectedDataIDs.length; i++) {
              const id = state.selectedDataIDs[i];
              const item = getters.getDataById(id);
              selectedData.push(item ? item : { id: -id, empty: true})
            }

            return selectedData;
        },

        // Returns all the unique values inside of our data
        // in a object by column name. 
        uniqueData(state) {
          const unique = {};
          const keys = Object.keys(state.schema);

          // Create a set for each column
          for (const key of keys) {
            unique[key] = new Set();
          }

          // Add all the values of each column to the 
          // corresponding unique setse.
          for (const d of state.data) {
              const dataKeys = Object.keys(d)
              for (const dk of dataKeys) {
                // Only add it if it the key is known inside the schema.
                if (keys.includes(dk)) unique[dk].add(d[dk]);
              }
          }

          // Replacing null with the string equivalent. 
          for (const key of keys) {
            if (unique[key].has(null)){
              unique[key].delete(null);
            } 
            unique[key].add("null")
          }

          return unique;
        },

        filteredData(state){
          var filtered = state.orFilter ? new Set() : state.data;
         
          // The keys of the filter contain the columns there is being filtered on.
          const filterKeys = Object.keys(state.filter);
          if (filterKeys.length == 0) return state.data;
          
          for (const key of filterKeys) {
            
            // The array containing the chips filled in by the user.
            const equalArray = state.filter[key].slice(); // make a copy of the filter.
            if (equalArray.length == 0) continue;
            if (equalArray.includes("null")) {
              equalArray.push(null);
            }
            
            if (state.orFilter) {
              // OR case:
              // Filter on the elements with the equals array.
              const data = state.data.filter(d => equalArray.includes(d[key]))
              for (const d of data) filtered.add(d); // Add all the filtered data to the filter.

            } else {
              
              // AND case.
              filtered = filtered.filter(d => equalArray.includes(d[key]))
            }
          
          }

          return Array.from(filtered);
        },

        amountOfFilters(state){
          const keys = Object.keys(state.filter);
          return keys.filter( k => state.filter[k].length > 0).length;
          // return keys.length;
        }

      },

      
      mutations: {

        setSchema(state, { schema }) {
          Vue.set(state, "schema", schema); // Use Vue.set to ensure that Vue reactivity will correctly work everywhere.
        },

        setData(state, { data }) {
          Vue.set(state, "data", data); // Use Vue.set to ensure that Vue reactivity will correctly work everywhere.
        },

        // Set the filter of data. 
        // orFilter indicates if the filter should be ORed or AND together.
        setFilter(state, { filter, orFilter }) {
          state.orFilter = orFilter;
          Vue.set(state, "filter", filter);
        },

        // TODO: Be able to append to data.
        // Append to data

        setSelectedDataIDs(state, { selectedDataIDs }) {
          // Get all the 
          const ids = selectedDataIDs.map( id => parseInt(id)).filter(id => !isNaN(id) );
          
          // TODO: Change the route here if it has not been set before.
          // Try to draw this workflow.

          Vue.set(state, "selectedDataIDs", selectedDataIDs);
        },

        // Update an existing data entry based on the the ID (overwrites)
        // Inserts if the ID has not been found before.
        // Can be needed for paginization
        updateID(state, { data }) {
          // TODO:
          // 1. check if exists (if not add it)
          // 2. if exists use vue.set to set the data.
        },

        deleteID(state, id){
          const index = state.data.findIndex(o => o.id == id)
          Vue.delete(state.data, index);
          Vue.delete(state.selectedDataIDs, state.selectedDataIDs.indexOf(id));
        },

        setShow(state, bool) {
            state.show = bool;
        },

        // Set the passphrase.
        setPassphrase(state, {passphrase}) {
          localStorage.setItem("passphrase", passphrase)
          state.passphrase = passphrase;
        },

        setMessage(state, message) {
          state.showMessage = true;
          state.message = message;
        },

        clearMessage(state){
          state.showMessage = false;
          state.message = "";
        }

        // Add appendData in the future when we will use this as a cache.

      },

      actions: {

        // For now it will be ugly since we are fetching the entire table this time. (will need paginzation in the future.)
        // I know I know - it is probably just a bad practice to begin with and you should just implement paginization....
        async fetchData({ commit, state }) {
          const schema = await apiService.fetchSchema(state.resource)
          const data = await apiService.fetchData(state.resource)
          if (schema?.errors || data?.errors) {
            throw "An error occured while communicating with the server."
          }
          commit("setSchema", { schema: schema.data })
          commit("setData", { data: data.data})
        },

        
        showMessage({ commit }, message) {
          commit("setMessage", message);
        },


        askPassphrase({ commit, state }){
          //if (state.passphrase == "") {
            var passphrase = prompt("This is a secured page - please fill in our shared passphrase.")
            commit("setPassphrase", { passphrase })
          //}
        },

        async deleteID( { commit, state}, id ) {
          const deletion = await apiService.deleteID(state.resource, id);
          if (deletion?.errors) {
            throw "An occured while deleting an element";
          } 
          commit("deleteID", id);
        }

        
      },



    // plugins: [createPersistedState()]
})
export default store;


function combineErrors(...errors){
  const combined = [] 
  errors.forEach( errorArray => {

  })
}