
<template>
    
        <div class="data-view">

        <v-card elevation="3" @keyup.enter="save"> 
            <v-toolbar color="" dark v-if="!empty"> 
                {{ item.company_name }} {{ edited ? "(edited)" : ""}} 
                

                 <v-btn color="white" elevation="2" @click="revertChanges" class="revert-button" v-if="edited && !saving">
                    <!-- <v-icon> mdi-power </v-icon> -->
                    Revert Changes
                </v-btn>

                <v-btn color="red" elevation="2" @click="deleteItem" class="delete-button" >
                    <!-- <v-icon> mdi-power </v-icon> -->
                    Delete
                </v-btn>
    

                <v-btn :color=" edited ? 'blue' : 'grey'" elevation="2" :loading="saving" @click="save" class="save-button">
                    <!-- <v-icon> mdi-power </v-icon> -->
                    Save
                </v-btn>
    
            </v-toolbar>
            <v-container class="wrapper" ref="wrapper" v-if="!empty">

                <div class="section" v-for="sectionName in Object.keys(sections)" :key="'section-'+sectionName">
                    <h3> {{ titleCase(sectionName) }} </h3>
                    <div class="section-line" v-for="line, index in sections[sectionName]" :key="'section-line-' + sectionName + '-' + index">
                        <span v-for="key in line" :key="key"> 

                        <v-text-field
                            v-if="getSetting(key, 'type', 'text') == 'text'"
                            v-model="item[key]"
                            :label="titleCase(key)"
                            :style=" { 'width': getSetting(key, 'width', 'unset')}"
                            :readonly="getSetting(key, 'readOnly', false)"
                            @focus="setFocusToggle(key, true)"
                            @blur="setFocusToggle(key, false)"
                        ></v-text-field>

                        <v-textarea
                            v-if="getSetting(key, 'type', 'text') == 'textarea'"
                            v-model="item[key]"
                            rows="9"
                            :label="titleCase(key)"
                            :hint="getSetting(key, 'hint', '')"
                            style="width: 335px; height: 300px"
                            @focus="setFocusToggle(key, true)"
                            @blur="setFocusToggle(key, false)"
                        ></v-textarea>

                        </span> 
                    </div>

                </div>


            </v-container>

            <v-toolbar color="red" dark v-if="empty">
                ID '{{ -item.id }}' niet gevonden in de database.
            </v-toolbar>
    

        </v-card>



    </div>

</template>

<script>
import { mapState } from 'vuex';
import apiService from '@/services/api.js'

export default {
    props: {
        readOnly: Boolean,
        dataItem: Object,
    },
    name: "DataView",
    data() {
        return {
            item: {},
            exclude: ["unknown_attributes"],
            saving: false,
            inputFocused: {}, // object with key of booleans.
            sections: {
                // Definition of a section of data that is editable.
                "address": [ ["address"], ["street_name"], ["postcode", "house_number", "housenumber_extenstion"], ["country", "place"] ],
                "contact_information": [ ["company_name"], ["contact_first_name", "contact_last_name"], ["scraped_email", "email"], ["phone_number", "website"]],
                "google_maps": [ ["google_maps_url"], ["google_maps_address"], ["opening_times"], ["google_rating"]],
                "meta": [ ["id"], ["status", "shop_type"], ["user_name", "source"], ["created_at", "updated_at"]],
                "notes": [ ["note"]],
           },

            // An array with custom widths for certian fields. (it thus has exceptions.)
            settings: {
                address: { width: "250px"},
                street_name: { width: "250px"},
                postcode: { width: "100px"},
                house_number: { width: "75px"},
                housenumber_extenstion: { width: "75px"},
                country: { width: "100px"},
                place: { width: "150px"},

            
                id: { width: "50px", readOnly: true },
                company_name: { width: "250px"},
                phone_number: { width: "150px"},
                website: { width: "200px"},
                note: { type: "textarea", hint: "General notes about this client" },
                
                google_maps_address: {width: "300px"},
                google_maps_url: {width: "300px"},
                opening_times: {width: "300px"},
                google_rating: { width: "150px" },  

                // Add different kind of view settings.
                created_at: { readOnly: true },
                updated_at: { readOnly: true }


            }
        }
    },  

    computed: {
        ...mapState(["schema", "resource"]),

        // Boolean if we edited one of the data values.
        edited(){
            const edited = this.changedKeys.length > 0;
            this.$set(this.dataItem, "edited", edited)
            return edited;
        },

        empty(){
            return this.item.empty;
        },

        schemaKeys(){
            return Object.keys(this.schema);
        },

        savingEnabled(){
            return this.edited && !this.saving && !this.fillingInData;
        },

        fillingInData(){
            // If everything is one input is focused we are filling in data.
            return Object.values(this.inputFocused).includes(true);
        },

        changedKeys(){
            var changedKeys = []

            for (const [key, value] of Object.entries(this.dataItem)) {
                if (this.exclude.includes(key)) continue;
                if (value != this.item[key]) changedKeys.push(key)
            }
            return changedKeys;
        },

    },

    watch: {

        dataInput(){
            this.setItem();
            // console.log(this.inputFocused);
        }

    },

    methods: {

        // We should try to save our data!
        // TODO: Use udpated at to check if our data has been updated.
        async save(){
            if (!this.savingEnabled) return;
            var data = {}

            // Send only the keys that we have chamged to the server.
            for (const key of this.changedKeys) {
                data[key] = this.item[key];
            }
            const body = { "lead": data }

            this.saving = true;
            try {

                // Send the changed keys to the server. (using our API service)
                const response = await apiService.updateData(this.resource, this.item.id, body)
                if (response?.errors) throw response.errors[0];
                if (!response.data) throw "Something went wrong"
                this.$store.dispatch("showMessage", "Succesfully updated data!")
                this.updateDataItem(data);  // Save our updates in the global store!

            } catch (error) {
                console.log("[Error]", error);
                this.$store.dispatch("showMessage", "Failed to upload data! Please try again.")
            }
            this.saving = false;
            
        },

        async deleteItem(){
            // Help we need to delete the item, what to doo!
            var del = confirm("Are you sure you want to delete '" + this.item.company_name + `' with ID ${this.item.id}?`)
            if (!del) return;
            try {
                await this.$store.dispatch("deleteID", this.item.id);
            } catch(error) {
                this.$store.dispatch("showMessage", "Failed to delete item.")
            }
        },

        // Used to keep track if an input has focus or not.
        setFocusToggle(key, focus=true){
            this.$set(this.inputFocused, key, focus);
        },

        setItem(){
            this.$set(this, "item", JSON.parse(JSON.stringify(this.dataItem)));
        },

        revertChanges(){
            this.setItem(); // Just set it back the the prop.
        },

        // I know, this is code duplication. TODO: Should be a shared code file somewhere.
        titleCase(string) {
            let sentence = string.toLowerCase().split("_");
            for (let i = 0; i < sentence.length; i++) {
                let word = sentence[i]
                sentence[i] = word == "id" ? "ID" : word[0].toUpperCase() + word.slice(1);
            }
            return sentence.join(" ");
        },

        getSetting(key, settingKey, defaultValue="") {
            var setting = this.settings?.[key]?.[settingKey]
            return setting ? setting : defaultValue;
        },


        // Update our global data item with the following new key and values pairs.
        updateDataItem(data) {
            const keys = Object.keys(data);
            for (const key of keys) { 
                this.$set(this.dataItem, key, data[key]);
            }
            
        }


    },

    mounted() {
        this.setItem();
    }

}

// === Address Part ===
// address (just a big one liner.)
// street_name, house_number, house_number_extension, postcode, place, country

// === Contact Information ===
// company_name, contact_first_name, contact_last_name
// scraped_email, email, phone_number, website
//

// == Google Maps ==
// google_maps_address
// google_maps_url
// google_rating
// opening_times

// === Note ====
// note (large text box for notes about this lead.)


// ==== Other (Meta) ===
// id
// status
// shop_type
// source 
// user_name
// unknown_attributes
// updated_at
// created_at

// address: "text"
// company_name: "text"
// contact_first_name: "string"
// contact_last_name: "string"
// contact_name: "text"
// country: "text"
// created_at: "datetime"
// email: "text"
// google_maps_address: "text"
// google_maps_url: "text"
// google_rating: "text"
// house_number: "string"
// house_number_extension: "string"
// id: "integer"
// note: "text"
// opening_times: "text"
// phone_number: "text"
// place: "text"
// postcode: "text"
// scraped_email: "boolean"
// shop_type: "text"
// source: "string"
// status: "string"
// street_name: "string"
// unknown_attributes: "text"
// updated_at: "datetime"
// user_name: "string"
// website: "text"

</script>

<style>

    .data-view {
        margin-bottom: 20px;
    }

    .data-view .wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .data-view .wrapper span {
        padding-right: 10px;
            /* padding-bottom: 10px; */
    }

    .data-view .section-line {
        display: flex;
        flex-direction: row;
    }

    .data-view .section {
            padding: 10px;
    }

    .data-view .save-button,  .data-view .revert-button, .data-view .delete-button {
        color: black;
        position: absolute;
    }

    .data-view .save-button  {
        color: white;
        right: 20px;
    }

    .data-view .revert-button {
        right: 200px;
    }

    .delete-button {
        right: 100px;
    }

</style>

