<template>
  <v-app>
    <v-toolbar
        color="#26262b"
        dark
        flat
        style="flex-grow: 0; "
        prominent
    >
        
        <v-toolbar-title style="font-size: 2em; font-weight: 800;">
          <img class="logo" src="@/assets/logo-pixelhobby.png" /> Leads and Prospects
        </v-toolbar-title>
      
      </v-toolbar>
    <v-main>

      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style >
  
  .logo {
      width: 50px;
      margin-left: 7px;
      margin-right: 12px;
      margin-bottom: -5px;
  }

</style>