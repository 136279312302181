<template>
    <div class="data-list">

        <v-card elevation="3">
            <v-card-title>

                <v-text-field
                    v-model="search"
                    label="Search"
                    single-line
                    hide-details
                >
                
                 <template v-slot:append>
                    <Filters :columns="Object.keys($store.state.schema)"/>
                    <v-icon> mdi-magnify </v-icon>
                </template>
                
            </v-text-field>    
                
            </v-card-title>
        
            <div class="wrapper">
                <!-- <p> Vul hieronder alle namen onder elkaar in. </p> -->

                <!-- TODO: Show something while we are loading data. -->
                <v-data-table
                    item-key="id"
                    class="elevation-1"
                    :search="search"
                    :headers="schemaHeaders"
                    :items="$store.getters.filteredData"
                    :single-select="false"
                    :loading="loading"
                    v-model="selected"
                    @toggle-select-all="selectAll($event)"
                    show-select
                    loadingText="Loading data from database..."
                    select
                    sort-by="id"
                    sort-desc
                    :items-per-page="20"
                >


                    <template v-slot:header.data-table-select>
                        <v-icon v-if="selectedIDs.length > 0"  color="gray" class="remove-selection" @click="clearSelection">mdi-close</v-icon>
                    </template>

                    <template v-slot:header.created_at>
                        Days since addition                            
                    </template>

                    <template v-slot:item.created_at="{item}">
                        <v-chip color="">
                            {{ daysSinceToday(item.created_at) }} days                            
                        </v-chip>
                    </template>
                
                    <template v-slot:item.country="{ item }">
                        <span v-if="item.country && item.country.length == 2">
                            <img class="country-flag" :src="'https://www.countryflags.io/' + item.country.toLowerCase() + '/flat/64.png'"> ({{ item.country }})
                        </span>
                        <span v-else>
                            {{ item.country }}
                        </span>
                    </template>
                
                </v-data-table>

            </div>



        </v-card>

   </div>
</template>

<script>
import Filters from "../components/Filters.vue";

import { mapGetters, mapState } from 'vuex';
export default {
    name: "DataList",
    components: { Filters },

    data() {
        return {
            loading: true,
            search: "",
            selected: [],
            // This needs to be an array since we want to remember the order in which added items.
            // We also want to be able to change it and then selected should change as a result.
            selectedIDs: [], // The selected ID's, in order of addition. (this one is local whereass the selectedDataIDs is in the store)
            maxSelected: 3, // TODO: Just moved it because it was buggy - need to do some extensive testing with 3.
            showColumns: ["id", "company_name", "country", "status", "created_at"],
            // dataHeaders: [
            //     { text: "Company Name", value: 'company_name'}, 
            //     { text: "Contact", value: "contant_name" },
            //     ]
        }
    },

    computed: {
        ...mapState(["data", "schema", "selectedDataIDs"]),
        ...mapGetters(["filteredData"]),

        // Compute the data headers from the schema we have.
        schemaHeaders() {
            const headers = []
            for (const key of this.showColumns) {
                headers.push({ text: this.titleCase(key), value: key })
            }   
            return headers
        },

    },

    watch: {
        
        // We watch this to compute the check which IDs e need to remove from our list
        // TODO: Rework this entire watcher workflow.
        selected(newValue, oldVal) {
        
            // Find out which ID we just added.
            var oldIds = oldVal.map(d => d.id);
            var ids = newValue.map(d => d.id);

            // If we have the same array do nothing.
            if (this.checkArrayEquals(oldIds, ids)) return; 
        
            console.log("[Data List] Internal selected array got changed.");
            console.log(newValue, oldVal);

            if (ids.length > oldIds.length) {
                // We added something - find out what it is.
                let diff = ids.filter( id => !oldIds.includes(id));
                console.log("[DataList] Added IDs", ...diff, "from selection.");
                this.selectedIDs.push(...diff);
                
            } else if (ids.length < oldIds.length ) {
                let diff = oldIds.filter( id => !ids.includes(id))
                
                this.$set(this, "selectedIDs",  this.selectedIDs.filter(id => !diff.includes(id))) // Remove the difference from our selectedIDs
                if (this.preventRemovalWithChanges(diff, oldVal)) return;
                console.log("[DataList] Removed IDs", ...diff, "from selection.")
            }

            // Check if we added too much of these - remove the 'oldest' (first added) ID.
            if (newValue.length > this.maxSelected) {
                // Keep the one we selected and remove the oldest one in the IDs array.

                // The oldest one is the first that got added.
                let oldestID = this.selectedIDs[0]; 
                var newSelection = newValue.filter( d => d.id != oldestID); // Remove the oldest one!
                // selection contains actually objects.
                
                // Set it and update the checkmarks.
                this.setNewSelection(newSelection);

            } else if (newValue.length < this.maxSelected) {
                // Update the selectedIDs. (basically already done in the else if statement above)
            }
        },
        
        // If this one got changed - we should update the global one as well so we can render the other stuff.
        // We need to update our path which will then update a lot of other things.
        // This is a local change.
        selectedIDs(newVal) {
            console.log(newVal);
            // If the global is the same we do not have to update our route
            // since we assume that it already has been set. This way we do not get an infinite path change recursion.
            if (this.checkArrayEquals(this.selectedDataIDs, newVal)) return;
            this.$router.push({ path: "/leads/" + this.selectedIDs.join("+") })
        },


        // This one is mapped to the global store.
        // If it changes we should change our local selectedIDs.
        // and we need to update our checkmarks with setNewSelection.
        selectedDataIDs(){
            // We just changed - check to see if we are equal 
            var selectedIDFromSelected = this.selected.map(d => d.id)

            // We are the same - no need to change!
            if ((this.selectedDataIDs.length == this.selected.length && this.selectedDataIDs.every(id => selectedIDFromSelected.includes(id)))) return

            // TODO: Change the selected IDs we have here currently. (so we can close the items with an X)
            // console.log("We are different, we need to change the selected items");
            console.log("[Data List] Updating selected based on global selectedDataID");

            // This filter is dog slow - we should do something about it.
            const newSelectionObjects = this.data.filter( d => this.selectedDataIDs.includes(d.id));
            this.setNewSelection(newSelectionObjects);
        }

    },

    methods: {

        wait(n) {
            return new Promise(res => setTimeout(res, n));
        },
        
        titleCase(string) {
            let sentence = string.toLowerCase().split("_");
            for (let i = 0; i < sentence.length; i++) {
                let word = sentence[i]
                sentence[i] = word == "id" ? "ID" : word[0].toUpperCase() + word.slice(1);
            }
            return sentence.join(" ");
        },

        daysSinceToday(dateString){
            const oldDate = new Date(dateString);
            const today = new Date();
            return Math.floor((today - oldDate) / (1000 * 3600 * 24))
        },

        // Remove all the selections.
        clearSelection(){
            this.$set(this, "selected", [])
        },

        checkArrayEquals(arr1, arr2){
            if (arr1.length != arr2.length) return false;
            for (var i = 0; i < arr1.length; i++){
                if (arr1[i] != arr2[i]) return false;
            }
            return true
        },

        setSelectedIDs(ids){
            this.$set(this, "selectedIDs", ids);
        },

        setNewSelection(selection){
            // This should be done in a next tick so we can ensure the update of checkmarks
            this.$set(this, "selected", selection)
            this.$nextTick(() => this.$set(this, "selected", selection));

        },

        preventRemovalWithChanges(ids, selection) {
            const edited = []
            for (const item of selection) {
                if (!ids.includes(item.id)) continue;
                console.log(item.edited)
                if (!item.edited) continue;
                edited.push(item.id)
            }
            
            if (edited.length == 0) return false;
            var s = (edited.length == 1) ? `ID ${edited[0]} has` : `IDs ${edited.join(", ")} have` 
            s += " unsaved changes. If you continue these changes will be lost. Do you want to continue?"

            const prevent = !confirm(s);
            if (!prevent) return false;
            
            // this.$nextTick( () => this.setSelectedIDs(selection.map(s => s.id)));
            this.setNewSelection(selection); // Set the new selection to the old one.
            return true; // Prevent the change
        },
        
        async fetchData(){
            if (!this.fetchData?.tries) this.fetchData.tries = 0;
            try {
                await this.$store.dispatch("fetchData");
                this.$store.commit("setShow", true);
                // It was a success.
            } catch(error) {
                this.$store.dispatch("askPassphrase");
                if (window.document.hasFocus()) { 
                    window.location.reload();
                } else {
                    await this.wait(1000);
                    await this.fetchData();
                }
            }
        },
    },

    mounted() {
    console.log("[Data List] Component mounted");
       this.fetchData().then( () => this.loading = false); // Get all the data and then stop the loading.
    },

}



</script>

<style>
    
    .data-list .container {
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        justify-content: center;
    }

    .data-list .wrapper { 
        padding: 20px;
    }

    .data-list .remove-selection {
        cursor: pointer;
    }

    /* Hide the top checkmark of the table. */
    .data-list .v-data-table-header .text-start .v-simple-checkbox {   
        /* display: none; */
    }

    .container p {
        font-weight: 400;
        font-size: 1em;
        position: relative;
        /* top: -50px; */
        cursor: pointer;
        margin-bottom: 0px;
        /* margin-bottom: 16px;
        margin-top: -10px; */
        padding: 10px;

    }

    .data-list .country-flag {
        height: 30px;
    }

    .container img {
        /* width: 50%; */
        width: 12.5vh;
        user-drag: none;
        -webkit-user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;        
    }


</style>