<template>
  <v-dialog transition="dialog-top-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <!-- Should display the global state. -->
      <v-chip
        class="ma-2"
        v-on="on"
        v-bind="attrs"
        @click:close="removeFilters"
        :close="filtersRemovable"
      >
        {{ filterText }}
      </v-chip>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="" dark>Filtering on data</v-toolbar>
        <v-card-text>
          <br />
          <p>On which columns would you like to filter on?</p>
          <!-- Here we will need to selected the colors.  -->
          <v-autocomplete
            v-model="selectedColumns"
            :items="columns"
            chips
            label="Column names"
            deletable-chips
            full-width
            hide-details
            hide-no-data
            hide-selected
            multiple
            single-line
            solo
            clearable
            :search-input.sync="selectionInputs['autocomplete']"
            @change="selectionInputs['autocomplete'] = ''"
          ></v-autocomplete>
          <br />
          <br />
          <p v-if="didSelect">
            Now you can fill in the values you are filtering for each inidivual column
            down below. <br />
            There can be filtered for multiple values on each column.
          </p>
          <div v-for="column in selectedColumns" :key="column">
            <!-- Let the user fill in what value it needs to be -->
            <!-- These are an or by definition. -->
            <v-autocomplete
              class="filter-column-input"
              v-model="filters[column]"
              chips
              :items="getUniqueValues(column)"
              :label="column"
              hide-selected
              :search-input.sync="selectionInputs[column]"
              @change="selectionInputs[column] = ''"
              clearable
              multiple
              deletable-chips
            ></v-autocomplete>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-switch
                v-model="orFilter"
                v-bind="attrs"
                v-on="on"
                :label="orFilter ? 'OR' : 'AND'"
              ></v-switch>
            </template>
            <span>Tooltip</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="dialog.value = false">Exit</v-btn>
          <v-btn
            text
            @click="
              setFilter();
              dialog.value = false;
            "
            >Apply</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "Filters",
  props: {
    columns: Array,
  },

  data() {
    return {
      filters: {}, // Contains all the columns and values to be fitlered on.
      selectedColumns: [], // All the columns that we want to filter on.
      orFilter: true, // If this is an OR filter or an AND filter.
      selectionInputs: {}, // Used to make the autocorrect less buggy.
    };
  },

  computed: {
    didSelect() {
      return this.selectedColumns.length > 0;
    },

    filterText() {
      const filtersSet = this.$store.getters.amountOfFilters;
      if (filtersSet == 0) return "No Filters";
      if (filtersSet == 1) return "Filtering on 1 column";
      return `Filtering on ${filtersSet} columns`;
    },

    filtersRemovable() {
      const filtersSet = this.$store.getters.amountOfFilters;
      return filtersSet > 0;
    },
  },

  methods: {
    removeFilters() {
      this.$store.commit("setFilter", { orFilter: false, filter: {} });
      // Does set filter need to remove?
    },

    getUniqueValues(column) {
      return Array.from(this.$store.getters.uniqueData[column]);
    },

    // Put this filter inside of the global state since it got selected.
    setFilter() {
      this.$store.commit("setFilter", {
        orFilter: this.orFilter,
        filter: JSON.parse(JSON.stringify(this.filters)),
      });
    },
  },

  watch: {
    selectedColumns() {
    
        // Initialize the filter.
        for (const column of this.selectedColumns) {
            if (!this.filters[column]) this.filters[column] = [];
        }

        // Removal of old selected columns in the filter.
        let remove = Object.keys(this.filters).filter(x => !this.selectedColumns.includes(x))
        for (const key of remove) {
            this.filters[key] = undefined;
        }
    },
  },
};
</script>

<style>
.filter-column-input label.v-label--active {
  top: 0px !important;
}
</style>
